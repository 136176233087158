import React from 'react';

import "./styles.scss";

function WordListShow({ title, list_words }) {
  const propTypes = {
    title: title,
      words: list_words,
  };

  const words = list_words.map((w, idx) => (
    <div key={w + "-" + idx} className="WordList-col ClueList-list">
        {w}
    </div>
  ));

  return (
    <div className="WordList">
      {propTypes.title && (
        <div className="WordList-head">{propTypes.title}</div>
      )}
      <div className="WordList-body">
        {words}
      </div>
    </div>
  );
}
export default WordListShow;
