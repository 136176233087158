import React, { useEffect, useRef, useState } from "react";
import Avatar from "../../components/Avatar";
import RankingServices from "../../Services/Ranking/Player";
import { SpinnerCircular } from "spinners-react";
import { useTranslation } from "react-i18next";
import { AppTableRanking } from "../../components/app/admin/common/GlobalStyles";
import { setClassPosition } from "../../libs/utils";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Grid from "@material-ui/core/Grid";
import newIconStar from "../../assets/img/newIconStar.png";
import iconStar from "../../assets/img/iconStar.png";
import "./styles.scss";
import SearchIcon from "@material-ui/icons/Search";
const UserRanking = (props) => {
  return (
    <div
      className={
        "user-ranking-wrapper " +
        (props.current ? "user-ranking-wrapper-active" : "")
      }
    >
      <span
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          margin: "0 1em",
        }}
      >
        <span className="name">
          <Avatar
            size={"2em"}
            style={{ borderWidth: "2px" }}
            avatar={props.image}
          />
          <span className="ml-custom-1">
            {props.name == null
              ? "Sin Nombre"
              : `${props.name} ${props.lastName}`}
          </span>
        </span>

        <span className="points-custom">
          <img
            src={newIconStar}
            alt="Custom Icon"
            style={{ width: "15px", height: "15px", marginRight: "4px" }}
          />
          {props.points}
        </span>
      </span>
    </div>
  );
};

const UserMainRanking = (props) => {
  let rankingClass = "";

  if (props.position === 1) {
    rankingClass = "first-position-ranking";
  } else if (props.position === 2) {
    rankingClass = "second-position-ranking";
  } else if (props.position === 3) {
    rankingClass = "third-position-ranking";
  } else {
    rankingClass = "other-position-ranking";
  }

  return (
    <div
      className={
        "user-ranking-wrapper " +
        (props.current ? "user-ranking-wrapper-active" : "")
      }
      tabIndex={props.current ? "-1" : ""}
    >
      <span
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <span className="name-custom-ranking">
          {props.position && (
            <div
              className={`mr-1 span-position-ranking ${rankingClass}`}
              ref={(el) => {
                if (el) {
                  el.style.setProperty("color", "white", "important");
                }
              }}
            >
              {props.position}
            </div>
          )}

          <Avatar
            size={"2em"}
            style={{ borderWidth: "2px" }}
            avatar={props.image}
          />

          <span className="name">
            {props.name} {props.lastName}
          </span>

          <span className="features">
            {props.email == null ? "---" : props.email}
          </span>

          <span className="features">{props.teamName}</span>

          {<span className="features">{props.secondaryTeamName == null ? "---" : props.secondaryTeamName}</span>}

        </span>

        {props.insigniaCount && (
          <span className="points-custom">{props.insigniaCount}</span>
        )}
        <span className="points-custom">
          <img
            src={newIconStar}
            alt="Custom Icon"
            style={{
              width: "15px",
              height: "15px",
              marginRight: "4px",
              marginLeft: "3px",
            }}
          />
          {props.points != undefined ? props.points : 0}
        </span>
      </span>
    </div>
  );
};

const Ranking = (props) => {
  const [mainRankings, setMainRanking] = useState(null);
  const [historicalRankings, setHistoricalRanking] = useState(null);
  const [badgeRankings, setBadgeRanking] = useState(null);
  const [metricRankings, setMetricRanking] = useState(null);
  const [sesion, setSesion] = useState(null);
  const mainRankingRef = useRef(null);
  const historicalRankingsRef = useRef(null);
  const badgeRankingsRef = useRef(null);
  const metricRankingRef = useRef(null);
  const { t } = useTranslation();
  const [awardCategory, setAwardCategory] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  
  const [currentPage2, setCurrentPage2] = useState(1);
  const [itemsPerPage2, setItemsPerPage2] = useState(5);
  const [searchQuery2, setSearchQuery2] = useState("");

  
  const filteredRankings = mainRankings
    ? mainRankings.filter((data) =>
        data.user.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];
  
  const filteredRankings2 = historicalRankings
    ? historicalRankings.filter((data) =>
        data.user.name.toLowerCase().includes(searchQuery2.toLowerCase())
      )
    : [];

  //número total de páginas
  const totalPages = filteredRankings ? Math.ceil(filteredRankings.length / itemsPerPage) : 0;
const totalPages2 = filteredRankings2 ? Math.ceil(filteredRankings2.length / itemsPerPage2) : 0;

useEffect(() => {
  if (mainRankings && sesion && sesion.user) {
    const sesionUserIndex = mainRankings.findIndex(
      (rank) => rank.user.id === sesion.user.id
    );

    if (sesionUserIndex !== -1) {
      // Calcular la página en la que se encuentra el usuario
      const userPage = Math.ceil((sesionUserIndex + 1) / itemsPerPage);
      setCurrentPage(userPage); // Establecer la página del usuario
    }
  }
}, [mainRankings, sesion, itemsPerPage]);

useEffect(() => {
  if (historicalRankings && sesion && sesion.user) {
    const sesionUserIndex = historicalRankings.findIndex(
      (rank) => rank.user.id === sesion.user.id
    );

    if (sesionUserIndex !== -1) {
      // Calcular la página en la que se encuentra el usuario
      const userPage = Math.ceil((sesionUserIndex + 1) / itemsPerPage2);
      setCurrentPage2(userPage); // Establecer la página del usuario
    }
  }
}, [historicalRankings, sesion, itemsPerPage2]);

  useEffect(() => {
    if (currentPage > totalPages && totalPages > 0) {
      setCurrentPage(totalPages);
    }
  }, [filteredRankings, totalPages]);
  
  useEffect(() => {
    if (currentPage2 > totalPages2 && totalPages2 > 0) {
      setCurrentPage2(totalPages2);
    }
  }, [filteredRankings2, totalPages2]);
  
 
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);
  
  useEffect(() => {
    setCurrentPage2(1);
  }, [searchQuery2]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  
  const handlePageChange2 = (pageNumber) => {
    setCurrentPage2(pageNumber);
  };

  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredRankings.slice(indexOfFirstItem, indexOfLastItem);

  const indexOfLastItem2 = currentPage2 * itemsPerPage2;
  const indexOfFirstItem2 = indexOfLastItem2 - itemsPerPage2;
  const currentItems2 = filteredRankings2.slice(indexOfFirstItem2, indexOfLastItem2);

  //paginación
  const Pagination = ({ totalPages, currentPage, handlePageChange }) => {
    return (
      <div className="pagination">
       
        <button 
          onClick={() => handlePageChange(currentPage - 1)} 
          disabled={currentPage === 1}
        >
          &laquo; 
        </button>
  
     
        <span className="current-page">
          {currentPage} de {totalPages}
        </span>
  
        <button 
          onClick={() => handlePageChange(currentPage + 1)} 
          disabled={currentPage === totalPages}
        >
          &raquo;
        </button>
      </div>
    );
  };
  const Pagination2 = ({ totalPages2, currentPage2, handlePageChange2 }) => {
    return (
      <div className="pagination2">

        <button 
          onClick={() => handlePageChange2(currentPage2 - 1)} 
          disabled={currentPage2 === 1}
        >
          &laquo; 
        </button>
  
        <span className="current-page2">
          {currentPage2} de {totalPages2}
        </span>
  
    
        <button 
          onClick={() => handlePageChange2(currentPage2 + 1)} 
          disabled={currentPage2 === totalPages2}
        >
          &raquo;
        </button>
      </div>
    );
  };
  
  

  

  useEffect(() => {
    setSesion(JSON.parse(localStorage.getItem("sesion")));

    const fechaInicial = new Date(new Date().getFullYear(), 0, 1);
    const fechaFinal = new Date(new Date().getFullYear() + 1, 0, 1);

    const año_inicial = fechaInicial.getFullYear();
    const año_final = fechaFinal.getFullYear();
    const mes = ("0" + (fechaInicial.getMonth() + 1)).slice(-2);
    const dia = ("0" + fechaInicial.getDate()).slice(-2);
    const fechaFormateadaInicial = `${año_inicial}-${mes}-${dia}T00:00:00`;
    const fechaFormateadaFinal = `${año_final}-${mes}-${dia}T00:00:00`;

    RankingServices.GetMainRankingAwarCategory()
      .then((response) => {
        setMainRanking(response.data.content);
      })
      .catch((error) => {
        setMainRanking([]);
      });

    RankingServices.GetHistoricalRankingAwarCategory(fechaFormateadaInicial, fechaFormateadaFinal)
      .then((response) => {
        setHistoricalRanking(response.data.content);
      })
      .catch((error) => {
        setHistoricalRanking([]);
      });
  }, []);

  const sortingData = (ranking) => {
    if (!ranking || !sesion) return [];

    const sesionId = sesion.user.id;

    const sesionUserIndex = ranking.findIndex(
      (rank) => rank.user.id === sesionId
    );

    if (sesionUserIndex === 2 || sesionUserIndex === ranking.length - 3) {
      return ranking;
    }

    const ranksWithSamePosition = ranking.filter(
      (rank) => rank.position === ranking[sesionUserIndex].position
    );

    const userRanking =
      ranksWithSamePosition.length <= 1
        ? ranking
        : [...ranking].sort((firstRank, secondRank) => {
            if (firstRank.position !== secondRank.position)
              return firstRank.position - secondRank.position;

            if (sesionId === firstRank.user.id) return 0;

            if (sesionId === secondRank.user.id) {
              return 1;
            }
          });

    return userRanking;
  };

  useEffect(() => {
    if (sesion && sesion.user && sesion.user.awardCategory) {
      setAwardCategory(sesion.user.awardCategory);
    }
  }, [sesion]);

  useEffect(() => {
    if (mainRankingRef.current && mainRankingRef.current.children.length > 0) {
      const sesionUserIndex = mainRankings.findIndex(
        (rank) => rank.user.id === sesion.user.id
      );
      const collectionArray = Array.from(mainRankingRef.current.children);

      if (sesionUserIndex > 2)
        mainRankingRef.current.scroll(
          0,
          collectionArray[sesionUserIndex - 1]
        );
    }

    if (
      badgeRankingsRef.current &&
      badgeRankingsRef.current.children.length > 0
    ) {
      const sesionUserIndex = badgeRankings.findIndex(
        (rank) => rank.user.id === sesion.user.id
      );
      const collectionArray = Array.from(badgeRankingsRef.current.children);
      if (sesionUserIndex > 2)
        badgeRankingsRef.current.scroll(
          0,
          collectionArray[sesionUserIndex - 1]
        );
    }

    if (
      historicalRankingsRef.current &&
      historicalRankingsRef.current.children.length > 0
    ) {
      const sesionUserIndex = historicalRankings.findIndex(
        (rank) => rank.user.id === sesion.user.id
      );
      const collectionArray = Array.from(
        historicalRankingsRef.current.children
      );
      if (sesionUserIndex > 2)
        historicalRankingsRef.current.scroll(
          0,
          collectionArray[sesionUserIndex - 1]
        );
    }

    if (
      metricRankingRef.current &&
      metricRankingRef.current.children.length > 0
    ) {
      const sesionUserIndex = metricRankings[0].users.findIndex(
        (rank) => rank.user.id === sesion.user.id
      );
      const collectionArray = Array.from(metricRankingRef.current.children);
      if (sesionUserIndex > 2) {
        metricRankingRef.current.scroll(
          0,
          collectionArray[sesionUserIndex - 1].offsetTop
        );
      }
    }
  }, [mainRankings, historicalRankings, badgeRankings, metricRankings]);

  if (!sesion) return "";

  return (
    <div className="ranking-wrapper">
      <Grid container className="ranking-wrapper-inner">
 <Grid item className="ranking-box" xs={12}>

 <div className="header-container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
 <h3>
  {t(
    sesion.user.company_Id === 5
      ? "total-ranking-month.label.5"
      : "total-ranking-month.label"
  ).toUpperCase()}{" "}
  {awardCategory ? ` - ${awardCategory}`.toUpperCase() : ""}
</h3>  
  <div className="search-container" style={{ display: 'flex', alignItems: 'center', marginTop: '55px' }}>
    <input
      type="text"
      className="search-input"
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      placeholder="Buscar por nombre..."

    />
    <SearchIcon className="search-icon" />
  </div>
</div>


  

          {/* Filtro de búsqueda */}
       





          {mainRankings === null ? (
            <span className="spinner-container">
              <SpinnerCircular />
            </span>
          ) : (
            <div className="card-model ranking-list-content2 custom-height-metrics" ref={mainRankingRef}>
              {currentItems.map((data, index) => (
                <UserMainRanking
                  position={data.position}
                  name={data.user.name}
                  lastName={data.user.lastName}
                  username={data.user.username}
                  points={data.pointBalance}
                  positionIndex={index}
                  current={sesion.user.id === data.user.id}
                  image={"/api" + data.user.image}
                  key={data.user.id}
                  insigniaIcon={false}
                  email={data.user.email}
                  teamName={data.user.teamName}
                  secondaryTeamName={data.user.secondaryTeamName}
                />
              ))}
            </div>
          )}
        </Grid>
     

      {/* Paginación */}
      <div className="pagination">
      <Pagination 
      totalPages={totalPages} 
      currentPage={currentPage} 
      handlePageChange={handlePageChange} 
    />
      </div>
        
        <Grid
          item
          className="ranking-box"
          /*md={sesion.user.company_Id != 5 ? 4 : 6}*/ xs={12}
          
        >
          
      
    
          <div className="header-container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <h3>
  {t(
    sesion.user.company_Id === 5
      ? "anual-ranking.label.5"
      : "anual-ranking.label"
  ).toUpperCase()}{" "}
  {awardCategory ? ` - ${awardCategory}`.toUpperCase() : ""}
</h3>
  <div className="search-container2" style={{ display: 'flex', alignItems: 'center', marginTop: '67px'  }}>
    <input
      type="text"
      className="search-input2"
      placeholder="Buscar por nombre..."
      value={searchQuery2}
      onChange={(e) => setSearchQuery2(e.target.value)}
   
    />
    <SearchIcon className="search-icon2" />
  </div>
</div>

          {historicalRankings === null ? (
            <span className="spinner-container">
              <SpinnerCircular />
            </span>
          ) : (
            <AppTableRanking>
              <div className="card-model">
                <div
                  className="ranking-list-content custom-height-metrics"
                  ref={historicalRankingsRef}
                >
                  {currentItems2.map((data, index) => {
                    return (
                      <UserMainRanking
                        position={data.position}
                        name={data.user.name}
                        lastName={data.user.lastName}
                        username={data.user.username}
                        points={data.pointBalance}
                        positionIndex={index}
                        current={sesion.user.id === data.user.id}
                        image={"/api" + data.user.image}
                        key={data.user.id}
                        insigniaIcon={false}
                        email={data.user.email}
                        teamName={data.user.teamName}
                        secondaryTeamName={
                          data.user.secondaryTeamName}
                      />
                    );
                  })}
                </div>
               <div className="pagination2">
    <Pagination2 
        totalPages2={totalPages2} 
      currentPage2={currentPage2} 
      handlePageChange2={handlePageChange2} 
    />
    </div>
              </div>
              
            </AppTableRanking>
            
          )}
          
        </Grid>
        

        {/*sesion.user.company_Id != 5 && (
          <Grid item className="ranking-box" xs={12} md={4}>
            <h3>{t("menu.rigth-list-item-badges", "Badges").toUpperCase()}</h3>
            {badgeRankings === null ? (
              <span className="spinner-container">
                <SpinnerCircular />
              </span>
            ) : (
              <AppTableRanking>
                <div className="card-model">
                  <div
                    className="ranking-list-content custom-height-metrics"
                    ref={badgeRankingsRef}
                  >
                    {sortingData(badgeRankings).map((data, index) => {
                      return (
                        <UserMainRanking
                          position={data.position}
                          name={data.user.name}
                          lastName={data.user.lastName}
                          username={data.user.username}
                          positionIndex={index}
                          current={sesion.user.id === data.user.id}
                          insigniaCount={
                            data.insigniaCount ? data.insigniaCount : "0"
                          }
                          image={"/api" + data.user.image}
                          key={data.user.id}
                          insigniaIcon={true}
                        />
                      );
                    })}
                  </div>
                </div>
              </AppTableRanking>
            )}
          </Grid>
                  )*/}
      </Grid>
     {/*  {sesion.user.company_Id != 5 && (
        <>
          {metricRankings === null ? (
            <span className="spinner-container">
              <SpinnerCircular />
            </span>
          ) : (
            <Grid container spacing={4} className="ranking-wrapper-inner">
              {metricRankings.map(({ metricConf, users }, index) => (
                <Grid item xs={12} md={6}>
                  <span className="ranking-box" key={index}>
                    <h3>{metricConf.name.toUpperCase()}</h3>

                    <AppTableRanking>
                      <div className="card-model">
                        <div
                          className="ranking-list-content ranking-list-content-custom-style custom-height-metrics"
                          ref={metricRankingRef}
                        >
                          {users.map((user, index) => (
                            <UserRanking
                              name={user.user.name}
                              lastName={user.user.lastName}
                              points={user.points}
                              current={sesion.user.id === user.userId}
                              positionIndex={index}
                              image={"/api" + user.userImage}
                              key={user.userId}
                            />
                          ))}
                        </div>
                      </div>
                    </AppTableRanking>
                  </span>
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )} */}
    </div>
  );
};

export default Ranking;



