import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { AppWrapper } from "../../common/GlobalStyles";
import { SpinnerCircular } from "spinners-react";


import TablePagination from "@material-ui/core/TablePagination";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { Box } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useTranslation } from "react-i18next";
import GeneralFunctions from "../../libs/GeneralFunctions";
import { es } from "date-fns/locale";
import Delete from "../../common/Dialogs/Delete";
import LetterSoupService from "../../Services/LetterSoupService";
import toastr from "toastr";
import { format } from "date-fns";
const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  container: {
    marginTop: "2rem",
  },
}));
const LetterSoupDetails = (props) => {
    
  const { t } = useTranslation();
  const [cargando, setCargando] =  useState(false);
  const [data, setData] =  useState([]);
  useEffect(() => {
    setData(props.data);
  })

  const resetSopa = (id) => {
    props.onReset(id);
  };
  
  const classes = useStyles();
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  return (
    <Dialog open={props.open} fullScreen className="new-mission-wrapper">
      <AppWrapper>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              onClick={() => {
                props.onClose();
                /*if (!fetching) 
                    setUsers(null);
                    setData(null);*/
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props.title}
            </Typography>
          </Toolbar>
        </AppBar>
        {!(props.cargando || cargando)?
        (
          <TableContainer component={Paper} className="mt-2">
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">
                              {t("user.label", "User")}
                            </TableCell>
                            <TableCell align="left">
                              Fecha
                            </TableCell>    
                            <TableCell align="center">
                              Puntos
                            </TableCell>  
                            <TableCell align="center">
                              Accion
                            </TableCell>                         
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {data.map((val, index) => (
                            <TableRow
                              key={index}
                            >
                              <TableCell align="left">
                                {val.user.username}
                              </TableCell>

                              <TableCell align="left">{format(new Date(val.created), "dd/MMM/yyyy HH:mm:ss", {locale: es,})}</TableCell>
                              <TableCell align="center" >{val.points}</TableCell>
                              <TableCell align="center" >
                              <Delete
                                title="Resetear Sopa"
                                message="¿Deseas resetear la sopa del usuario?"
                                deleteMetric={() => resetSopa(val.id)}
                              /></TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

        ):
        (<span className="spinner-container">
            <SpinnerCircular />
          </span>)
        }
        
      </AppWrapper>
    </Dialog>
  );
};

LetterSoupDetails.defaultProps = {
  onClose: () => {},
  data: null,
  onUpdate: () => {},
  open: false,
  onReset: () => {},
};

export default LetterSoupDetails;
