import React, { useState } from 'react';
import './Wordsearch.css';
//import Letter from './Letter';
import './Letter.css';
import { create2dArray, create2dJsonArray } from './utils';
import Cursor from './Cursor';

function Letter({ llave, estilo, chart, handleOnClick }) {
  //onMouseOver={handleOnOver}
  return (
    <div key={llave} onClick={handleOnClick} className={`Letter ${estilo}`}>
    {chart}
    </div>

  );
}
//export default Letter;

function getLetterChildren(height, width, solution) {
  const children = create2dArray(height, width, '');
  solution.forEach((word, wordIndex) => {
    if (!word.found) return;
    const c = new Cursor(word.firstLetter, word.lastLetter);
    word.word.split('').forEach((letter, letterIndex) => {
      const p = c.getPosition(letterIndex);
      const key = `${wordIndex}-${letterIndex}`;
      children[p.row][p.col] = children[p.row][p.col] || [<span className="Letter-highlight" key={`${key}-1`} />];
      if (letterIndex < word.word.length - 1) {
        children[p.row][p.col].push(<span className={`Letter -highlight Letter-highlight--join${c.dir}`} key={`${key}-2`} />);
      }
    });
  });
  return children;
}

function Wordsearch({ sopa, solution, showSolution, handleWordFinded }) {
  const [puzzle, setPuzzle] = useState(sopa);
  const [respuesta, setRespuesta] = useState(solution);
  const [mostrarSolution, setMostrarSolution] = useState(showSolution);
  const [selected, setSelected] = useState(null);
  const [puntoInicial, setpuntoInicial] = useState({ x: -1, y: -1 });
  const [puntoFinal, setpuntoFinal] = useState({ x: -1, y: -1 });
  const [colorPosicion, setColorPosicion] = useState(
    create2dJsonArray(sopa.length, sopa.length, ''),
  );
  const [banderaColor, setBanderaColor] = useState(1);
  const [newWord, setNewWord] = useState([]);
  const [dirMove, setDirMove] = useState(null);

  // retorna verdadero cuando enta en la misma fila o columna o diagonal
  const isRecto = (cI, cF) => {
    if ((cI.x === cF.x) || (cI.y === cF.y)) return true;
    if (Math.abs(cF.y - cI.y) === Math.abs(cI.x - cF.x)) return true;
    return false;
  }

  const direccionSentido = (p1, p2) => {

    let adjRow = Math.min(Math.max(p2.x - p1.x, -1), 1);
    let adjCol = Math.min(Math.max(p2.y - p1.y, -1), 1);
    let forwards = adjRow >= 0 && adjCol >= 0;

    let dir = '';
    if (adjRow < 0) {
      dir += 'N';
    } else if (adjRow > 0) {
      dir += 'S';
    }
    if (adjCol < 0) {
      dir += 'W';
    } else if (adjCol > 0) {
      dir += 'E';
    }
    return dir;
  }

  // a partir del puntoinicial y final segun al punto cardinal de movimiento
  // optener la palabra indicada por el usuario desde el puzzle
  const getPalabraInWords = (punto1, punto2) => {
    let auxPalabra = [];
    // si movimiento en fila columna o diagonal
    if (isRecto(punto1, punto2) && (punto1.x >= 0) && (punto1.y >= 0) && (punto2.x >= 0) && (punto2.y >= 0)) {
      const auxPuzzle = sopa.slice();
      const puntoCardinal = direccionSentido(punto1, punto2);
      switch (puntoCardinal) {
        case 'N':
          for (let i = punto1.x; i >= punto2.x; i -= 1) {
            auxPalabra.push(auxPuzzle[i][punto1.y]);
          }
          break;

        case 'S':
          for (let i = punto1.x; i <= punto2.x; i += 1) {
            auxPalabra.push(auxPuzzle[i][punto1.y]);
          }
          break;

        case 'E':
          for (let j = punto1.y; j <= punto2.y; j += 1) {
            auxPalabra.push(auxPuzzle[punto1.x][j]);
          }
          break;

        case 'W':
          for (let j = punto1.y; j >= punto2.y; j -= 1) {
            auxPalabra.push(auxPuzzle[punto1.x][j]);
          }
          break;

        case 'NE':
          for (let i = punto1.x, j = punto1.y; (i >= punto2.x && j <= punto2.y); i -= 1, j += 1) {
            auxPalabra.push(auxPuzzle[i][j]);
          }
          break;
        case 'NW':
          for (let i = punto1.x, j = punto1.y; (i >= punto2.x && j >= punto2.y); i -= 1, j -= 1) {
            auxPalabra.push(auxPuzzle[i][j]);
          }
          break;

        case 'SE':
          for (let i = punto1.x, j = punto1.y; (i <= punto2.x && j <= punto2.y); i += 1, j += 1) {
            auxPalabra.push(auxPuzzle[i][j]);
          }
          break;

        case 'SW':
          for (let i = punto1.x, j = punto1.y; (i <= punto2.x && j >= punto2.y); i += 1, j -= 1) {
            auxPalabra.push(auxPuzzle[i][j]);
          }
          break;

        default:
          auxPalabra = null;
          break;
      }
    }
    return auxPalabra;
  }

  // comprueba si la palabra forma parte de la solucion
  const isSolucion = (palabra) => {
    let aux_palabra = palabra;
    var ban = false;
    for (let i = 0; i < solution.length; i += 1) {
      if ((solution[i].word == aux_palabra) || (solution[i].word == aux_palabra.split('').reverse().join(''))) {
        ban = true;
        break;
      }
    }
    return ban;
  }

  const resetColorPosicionPalabra = () => {
    let aux = colorPosicion.slice();
    /*aux.forEach(row => {
      row.forEach(element => {
        if (element[1]==true) element = ['color-0', false];
        console.log("element[1]==true")
        console.log(element)
      });
    });*/
    for (let i = 0; i < sopa.length; i += 1) {
      for (let j = 0; j < sopa[i].length; j += 1) {
        if (aux[i][j][1])
          aux[i][j] = [`color-0`, false];
      }
    }
    setColorPosicion(aux);
    setpuntoInicial({ x: -1, y: -1 });
    setpuntoFinal({ x: -1, y: -1 });

  }

  /*const setColorPosicionPalabra = (x, y) => {
    let aux = colorPosicion.slice();

    if (aux[x][y][1] === true) aux[x][y] = ['color-0', false];

    setColorPosicion(aux);
  }*/

  const setColorPalabra = (punto1, punto2) => {
    let auxPalabra = [];
    // si movimiento en fila columna o diagonal
    if (isRecto(punto1, punto2) && (punto1.x >= 0) && (punto1.y >= 0) && (punto2.x >= 0) && (punto2.y >= 0)) {
      auxPalabra = colorPosicion.slice();
      const puntoCardinal = direccionSentido(punto1, punto2);
      switch (puntoCardinal) {
        case 'N':
          for (let i = punto1.x; i >= punto2.x; i -= 1) {
            auxPalabra[i][punto1.y] = [`color-letter-soup-${banderaColor}`, false];
          }
          break;

        case 'S':
          for (let i = punto1.x; i <= punto2.x; i += 1) {
            auxPalabra[i][punto1.y] = [`color-letter-soup-${banderaColor}`, false];
          }
          break;

        case 'E':
          for (let j = punto1.y; j <= punto2.y; j += 1) {
            auxPalabra[punto1.x][j] = [`color-letter-soup-${banderaColor}`, false];
          }
          break;

        case 'W':
          for (let j = punto1.y; j >= punto2.y; j -= 1) {
            auxPalabra[punto1.x][j] = [`color-letter-soup-${banderaColor}`, false];
          }
          break;

        case 'NE':
          for (let i = punto1.x, j = punto1.y; (i >= punto2.x && j <= punto2.y); i -= 1, j += 1) {
            auxPalabra[i][j] = [`color-letter-soup-${banderaColor}`, false];
          }
          break;
        case 'NW':
          for (let i = punto1.x, j = punto1.y; (i >= punto2.x && j >= punto2.y); i -= 1, j -= 1) {
            auxPalabra[i][j] = [`color-letter-soup-${banderaColor}`, false];
          }
          break;

        case 'SE':
          for (let i = punto1.x, j = punto1.y; (i <= punto2.x && j <= punto2.y); i += 1, j += 1) {
            auxPalabra[i][j] = [`color-letter-soup-${banderaColor}`, false];
          }
          break;

        case 'SW':
          for (let i = punto1.x, j = punto1.y; (i <= punto2.x && j >= punto2.y); i += 1, j -= 1) {
            auxPalabra[i][j] = [`color-letter-soup-${banderaColor}`, false];
          }
          break;

        default:
          auxPalabra = null;
          break;
      }
    }
    setColorPosicion(auxPalabra);
    setpuntoInicial({ x: -1, y: -1 });
    setpuntoFinal({ x: -1, y: -1 });
  }

  const handleOver = (x, y, c) => {
    if (selected && isRecto(puntoInicial, { x: x, y: y })) {
      let dir = direccionSentido(puntoFinal, { x: x, y: y });
      //setpuntoInicial({ x: x, y: y });
      let aux = newWord.slice();
      if (dirMove) {
        if (dirMove === dir) {
          //setDirMove(dir);
          aux.push(c);
          setNewWord(aux);
          let auxC = colorPosicion.slice();
          auxC[x][y] = [`color-letter-soup-${banderaColor}`, true];
          setColorPosicion(auxC);
          setpuntoFinal({ x: x, y: y });
        }
        else if (aux.length === 2) {
          aux.pop();
          setNewWord(aux);
          //setColorPosicionPalabra(puntoFinal.x, puntoFinal.y);
          setpuntoFinal({ x: x, y: y });
          // setDirMove(dir);
          // aux.splice(1, aux.length - 1);
          //setNewWord(aux);
        }
      }
      else {
        setDirMove(dir);
        aux.push(c);
        setNewWord(aux);
        let auxC = colorPosicion.slice();
        auxC[x][y] = [`color-letter-soup-${banderaColor}`, true];
        setColorPosicion(auxC);

      }
    }
    else {
      // sigue con el estilo
      resetColorPosicionPalabra();
    }
    //console.log(dirMove);
  }

  const handleClick = (x, y, c) => {
    let auxPalabra = [];
    if (!selected) {
      setpuntoInicial({ x: x, y: y });
      setpuntoFinal({ x: x, y: y });
      let auxC = colorPosicion.slice();
      auxC[x][y] = [`color-letter-soup-${banderaColor}`, true];
      setColorPosicion(auxC);
    } else {
      setpuntoFinal({ x: x, y: y });
      if (isRecto(puntoInicial, { x: x, y: y })) {
        //si es recto y palabra correcta, cambiar el estilo de forma permanente a toda l a recta
        // obtener palabra entre punto inicial y final des puzzle
        auxPalabra = getPalabraInWords(puntoInicial, { x: x, y: y });
        auxPalabra = (auxPalabra) ? auxPalabra.join('') : '';
        //auxPalabra = String.valueOf(auxPalabra);
        // si la palabras forma parte de la respuesta
        if (isSolucion(auxPalabra)) {
          // poner el color de forma permanente entre punto 1 y punto 2
          setColorPalabra(puntoInicial, { x: x, y: y });
          setBanderaColor(prevC => prevC + 1);
          handleWordFinded(auxPalabra);
         /* console.log("auxPalabra");
          console.log(auxPalabra);*/

        } else {
          // sino   // resetear el color entre punto 1 y punto 2
          resetColorPosicionPalabra();
        }
        //resetColorPosicionPalabra();
      }
      else {
        // sino se le pone el estilo por defecto
        resetColorPosicionPalabra();
      }
      setNewWord([]);
      setDirMove(null);
      //resetColorPosicionPalabra();
    }
    setSelected((prevSlect) => !prevSlect);

  }

  const letterChildren = (solution && solution.length && showSolution)
    ? getLetterChildren(puzzle.length, puzzle[0].length, solution)
    : false;

  /* eslint-disable react/no-array-index-key */
  /*console.log("sopa");
  console.log(sopa);*/
  const grid = (sopa && sopa.length) ? sopa.map((row, rowIndex) => (
    (row && row.length) && <div className="Wordsearch-row" key={"rowletter-"+rowIndex}>
      {row.map((char, colIndex) => (
        <Letter
          llave={`${rowIndex}-${colIndex}`}
          chart={char}
          estilo={colorPosicion[rowIndex][colIndex][0]}
          handleOnClick={() => handleClick(rowIndex, colIndex, char)}

        />
      ))}
    </div>
  )) : [];

  return (
    <>
      <div className="Wordsearch">
        {grid}
      </div>
    </>

  );
}
export default Wordsearch;