import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import toastr from "toastr";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import Avatar from "../../../../Avatar";
import Button from "../../../../Button";
import { SpinnerCircular } from "spinners-react";

import RankingServices from "../../../../../Services/Ranking/Player";
import RankingService from "../../Services/RankingService";

import { Box } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import { NotRegistersFound } from "../../common/NotRegistersFound";
import Paper from "@material-ui/core/Paper";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import TextField from "@material-ui/core/TextField";
import Pagination from "@material-ui/lab/Pagination";

import { format } from "date-fns";

import { useTranslation } from "react-i18next";
import "./styles.scss";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const UserRanking = (props) => {
  const [points, setPoints] = useState(0);
  const [coins, setCoins] = useState(0);
  const [updating, setUpdating] = useState(false);
  const { t } = useTranslation();

  const handlePoints = (event) => {
    let value = event.target.value;
    setPoints(value);
  };

  const handleCoins = (event) => {
    let value = event.target.value;
    setCoins(value);
  };

  /*const handleSumary = (event) => {
    let value = event.target.value;
    setSumary(value);
  };*/

  const handleUpdate = () => {
    props.onUpdate(true);
    setUpdating(true);
    RankingService.UpdateRanking({
      id: props.id,
      //sumary: sumary,
      amountCoin: coins,
      amountPoint: points,
      userId: props.userId,
    })
      .then(() => {
        setUpdating(false);
        props.onUpdated();
        props.onUpdate(false);
        //setSumary("");
      })
      .catch(() => {
        setUpdating(false);
        props.onUpdate(false);
        props.onError();
      });
  };

  useEffect(() => {
    setPoints(props.points);
    setCoins(props.coins);
  }, []);

  return (
    <>
      {updating ? (
        <TableRow>
          <span className="spinner-container-custom">
            <SpinnerCircular />
          </span>
        </TableRow>
      ) : (
        <TableRow>
          <TableCell align="left">
            <Box display="flex" alignItems="center">
              <Avatar
                size={"2em"}
                style={{ borderWidth: "2px", marginRight: "8px" }}
              />
              <Box>{props.name}</Box>
            </Box>
          </TableCell>

          <TableCell align="left">
            <span className={"points " + (props.editable ? "out" : "in")}>
              {props.coins}
            </span>
            <span className={"points " + (props.editable ? "in" : "out")}>
              <input disabled={updating} value={coins} onChange={handleCoins} />
            </span>
          </TableCell>

          <TableCell align="left">
            <span className={"points " + (props.editable ? "out" : "in")}>
              {props.points}
            </span>
            <span className={"points " + (props.editable ? "in" : "out")}>
              <input
                disabled={updating}
                value={points}
                onChange={handlePoints}
              />
            </span>
          </TableCell>

          <TableCell
            align="left"
            className="user-ranking-content d-flex-custom"
          >
            {!props.editable ? (
              <Button
                className={!props.editable ? "in" : "out"}
                onClick={() => {
                  props.onEdit(props.userId);
                }}
                disabled={props.disabled}
              >
                {t("admin.actions-edit", "Edit")}
              </Button>
            ) : (
              ""
            )}
            {props.editable ? (
              <Button
                disabled={updating}
                onClick={handleUpdate}
                className={props.editable ? "in" : "out"}
                style={{ marginRight: "1em" }}
              >
                {t("admin.header-dropdown-dialog-actions-save", "Save")}
              </Button>
            ) : (
              ""
            )}
            {props.editable ? (
              <Button
                disabled={updating}
                secondary
                onClick={() => {
                  props.onEdit(null);
                }}
                className={props.editable ? "in" : "out"}
              >
                {t("admin.header-dropdown-dialog-actions-cancel", "Cancel")}
              </Button>
            ) : (
              ""
            )}
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
UserRanking.defaultProps = {
  points: 0,
  coins: 0,
  name: "",
};

export default function Ranking() {
  const [metricsRankings, setMetricRankings] = useState(null);
  const [badgeRankings, setBadgeRanking] = useState(null);
  const [mainRanking, setMainRankings] = useState(null);
  const [sesion, setSesion] = useState(null);
  const [editable, setEditable] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [historicalRankings, setHistoricalRanking] = useState(null);
  const [dateTo, setDateTo] = useState(calculate_next_day());
  const [dateFrom, setDateFrom] = useState(format(new Date(), "yyyy-MM-dd"));
  const [downloadingPointsRanking, setDownloadingPointsRanking] =
    useState(false);
  const [downloadingInsigniasRanking, setDownloadingInsigniasRanking] =
    useState(false);
  const [leadersRanking, setLeadersRanking] = useState(null);

  const [paginatedRankings, setPaginatedRankings] = useState([]); // Para almacenar rankings paginados
  const [totalPages, setTotalPages] = useState(0); // Para almacenar el total de páginas
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState(""); // Para almacenar el texto de búsqueda
  const [paginatedLeaders, setPaginatedLeaders] = useState([]); // Rankings paginados de leadersRanking
  const [totalLeadersPages, setTotalLeadersPages] = useState(0); // Total de páginas
  const [leadersPage, setLeadersPage] = useState(1);
  const [leadersSearchText, setLeadersSearchText] = useState(""); // Texto de búsqueda
  const [agentsRanking, setAgentsRanking] = useState([]); // Lista completa de agentes
  const [agentsSearchText, setAgentsSearchText] = useState(""); // Texto de búsqueda para agentes
  const [paginatedAgentsRanking, setPaginatedAgentsRanking] = useState([]); // Lista filtrada para agentes
  const [agentsPage, setAgentsPage] = useState(1); // Página actual de agentes
  const [agentsRowsPerPage] = useState(5); // Cantidad de filas por página para agentes
  const [totalAgentsPages, setTotalAgentsPages] = useState(0); // Total de páginas

  const [othersRanking, setOthersRanking] = useState(null);
  const [paginatedOthersRanking, setPaginatedOthersRanking] = useState([]); // Para almacenar rankings paginados
  const [totalOthersPages, setTotalOthersPages] = useState(0); // Para almacenar el total de páginas
  const [othersPage, setOthersPage] = useState(1);
  const [othersSearchText, setOthersSearchText] = useState(""); // Para almacenar el texto de búsqueda

  const [paginatedMainRanking, setPaginatedMainRanking] = useState([]); // Para almacenar rankings paginados
  const [totalMainPages, setTotalMainPages] = useState(0); // Para almacenar el total de páginas
  const [mainPage, setMainPage] = useState(1);
  const [mainSearchText, setMainSearchText] = useState(""); // Para almacenar el texto de búsqueda

  
  const [paginatedUsers, setPaginatedUsers] = useState([]); // Para almacenar usuarios paginados
  const [totalPages2, setTotalPages2] = useState(0); // Para almacenar el total de páginas
  const [currentPage, setCurrentPage] = useState(1); // Página actual
  const [searchText2, setSearchText2] = useState(""); // Texto de búsqueda

  // useEffect para manejar la paginación y búsqueda de usuarios
  useEffect(() => {
    // Suponiendo que metricsRankings es un array que contiene datos de rankings
    if (metricsRankings) {
      // Aquí asumo que cada ranking tiene una propiedad 'users'
      const allFilteredUsers = [];
  
      metricsRankings.forEach(data => {
        if (data.users) {
          const filteredUsers = data.users.filter(user =>
            `${user.user.name} ${user.user.lastName}`.toLowerCase().includes(searchText2.toLowerCase()) // Cambiar searchText a searchText2
          );
          allFilteredUsers.push(...filteredUsers); // Agregar los usuarios filtrados a una lista total
        }
      });
  
      const totalFilteredPages = Math.ceil(allFilteredUsers.length / 5);
      const startIndex = (currentPage - 1) * 5;
      const paginatedData = allFilteredUsers.slice(startIndex, startIndex + 5);
  
      setPaginatedUsers(paginatedData);
      setTotalPages2(totalFilteredPages);
    }
  }, [metricsRankings, searchText2, currentPage]); // Cambiar searchText a searchText2

  // Función para manejar cambios en el texto de búsqueda
  const handleSearchChange = (event) => {
    setSearchText2(event.target.value);
    setCurrentPage(1); // Reinicia la página al buscar
  };

  // Función para manejar el cambio de página
  const handlePageChange2 = (event, newPage) => {
    setCurrentPage(newPage);
  };
  // useEffect para manejar la paginación y búsqueda de main rankings
  useEffect(() => {
    if (mainRanking) {
      // Filtrar mainRanking
      const filteredRanking = mainRanking.filter((ranking) => {
        const fullName =
          `${ranking.user.name} ${ranking.user.lastName} ${ranking.user.email}`.toLowerCase();
        return fullName.includes(mainSearchText.toLowerCase());
      });

      // Calcular total de páginas basado en la lista filtrada
      const totalFilteredPages = Math.ceil(filteredRanking.length / 5); // Cambia 5 por el número de filas por página deseadas

      // Actualizar el estado de paginación
      const startIndex = (mainPage - 1) * 5; // Cambia 5 por el número de filas por página deseadas
      const paginatedData = filteredRanking.slice(startIndex, startIndex + 5); // Cambia 5 por el número de filas por página deseadas

      setPaginatedMainRanking(paginatedData);
      setTotalMainPages(totalFilteredPages); // Actualiza el total de páginas basado en el filtrado
    }
  }, [mainRanking, mainSearchText, mainPage]);

  // Función para manejar cambios en el texto de búsqueda
  const handleMainSearch = (event) => {
    setMainSearchText(event.target.value);
    setMainPage(1); // Reinicia la página al buscar
  };

  // Función para manejar el cambio de página
  const handleMainPageChange = (event, value) => {
    setMainPage(value); // Cambia la página
  };
  // useEffect para manejar la paginación y búsqueda de otros rankings
  useEffect(() => {
    if (othersRanking) {
      // Filtrar otrosRanking
      const filteredRanking = othersRanking.filter((ranking) => {
        const fullName =
          `${ranking.user.name} ${ranking.user.lastName} ${ranking.user.email}`.toLowerCase();
        return fullName.includes(othersSearchText.toLowerCase());
      });

      // Calcular total de páginas basado en la lista filtrada
      const totalFilteredPages = Math.ceil(filteredRanking.length / 5); // Cambia 5 por el número de filas por página deseadas

      // Actualizar el estado de paginación
      const startIndex = (othersPage - 1) * 5; // Cambia 5 por el número de filas por página deseadas
      const paginatedData = filteredRanking.slice(startIndex, startIndex + 5); // Cambia 5 por el número de filas por página deseadas

      setPaginatedOthersRanking(paginatedData);
      setTotalOthersPages(totalFilteredPages); // Actualiza el total de páginas basado en el filtrado
    }
  }, [othersRanking, othersSearchText, othersPage]);

  // Función para manejar cambios en el texto de búsqueda
  const handleOthersSearch = (event) => {
    setOthersSearchText(event.target.value);
    setOthersPage(1); // Reinicia la página al buscar
  };

  // Función para manejar el cambio de página
  const handleOthersPageChange = (event, value) => {
    setOthersPage(value); // Cambia la página
  };

  const ITEMS_PER_PAGE = 5;

  const { t } = useTranslation();
  var timer;
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    // Filtrar agentsRanking
    const filteredRanking = agentsRanking.filter((data) =>
      `${data.user.name} ${data.user.lastName} ${data.user.email}`
        .toLowerCase()
        .includes(agentsSearchText.toLowerCase())
    );

    // Calcular total de páginas basado en la lista filtrada
    const totalFilteredPages = Math.ceil(
      filteredRanking.length / agentsRowsPerPage
    );

    // Actualizar el estado de paginación
    const startIndex = (agentsPage - 1) * agentsRowsPerPage;
    const paginatedData = filteredRanking.slice(
      startIndex,
      startIndex + agentsRowsPerPage
    );

    setPaginatedAgentsRanking(paginatedData);
    setTotalAgentsPages(totalFilteredPages); // Actualiza el total de páginas basado en el filtrado
  }, [agentsRanking, agentsSearchText, agentsPage, agentsRowsPerPage]);

  const handleAgentsSearch = (searchText) => {
    setAgentsSearchText(searchText);
    setAgentsPage(1); // Reinicia la página al buscar
  };

  const handleAgentsPageChange = (event, value) => {
    setAgentsPage(value); // Cambia la página
  };
  useEffect(() => {
    getRankings(); // Asegúrate de que esta función esté definida y obtenga los rankings
  }, []);

  useEffect(() => {
    if (historicalRankings) {
      // Calcula el total de páginas y muestra los rankings paginados
      setTotalPages(Math.ceil(historicalRankings.length / ITEMS_PER_PAGE));
      setPaginatedRankings(
        historicalRankings.slice(
          (page - 1) * ITEMS_PER_PAGE,
          page * ITEMS_PER_PAGE
        )
      );
    }
  }, [historicalRankings, page]);

  const handleSearch = (searchText) => {
    const filteredRankings = historicalRankings.filter((ranking) =>
      ranking.user.name.toLowerCase().includes(searchText.toLowerCase())
    );

    // Actualiza los rankings paginados y el total de páginas basados en los resultados filtrados
    setTotalPages(Math.ceil(filteredRankings.length / ITEMS_PER_PAGE)); // Calcula el total de páginas
    setPaginatedRankings(filteredRankings.slice(0, ITEMS_PER_PAGE)); // Muestra solo los primeros 5 resultados
    setPage(1); // Regresa a la primera página
    setSearchText(searchText); // Actualiza el texto de búsqueda
  };

  useEffect(() => {
    if (searchText === "") {
      // Si no hay texto de búsqueda, resetear a los rankings originales
      if (historicalRankings) {
        setTotalPages(Math.ceil(historicalRankings.length / ITEMS_PER_PAGE));
        setPaginatedRankings(
          historicalRankings.slice(
            (page - 1) * ITEMS_PER_PAGE,
            page * ITEMS_PER_PAGE
          )
        );
      }
    } else {
      const filteredRankings = historicalRankings.filter((ranking) =>
        ranking.user.name.toLowerCase().includes(searchText.toLowerCase())
      );

      // Ajustar el paginado según los resultados filtrados
      setTotalPages(Math.ceil(filteredRankings.length / ITEMS_PER_PAGE)); // Calcula el total de páginas
      setPaginatedRankings(
        filteredRankings.slice(
          (page - 1) * ITEMS_PER_PAGE,
          page * ITEMS_PER_PAGE
        )
      ); // Muestra los rankings paginados
    }
  }, [page, searchText, historicalRankings]);

  const handleLeadersPageChange = (event, value) => {
    setLeadersPage(value);
  };

  // Efecto para paginación y carga inicial
  useEffect(() => {
    if (leadersRanking) {
      // Calcula total de páginas y rankings paginados
      setTotalLeadersPages(Math.ceil(leadersRanking.length / ITEMS_PER_PAGE));
      setPaginatedLeaders(
        leadersRanking.slice(
          (leadersPage - 1) * ITEMS_PER_PAGE,
          leadersPage * ITEMS_PER_PAGE
        )
      );
    }
  }, [leadersRanking, leadersPage]);

  // Función para manejar búsqueda
  const handleLeadersSearch = (searchText) => {
    const filteredLeaders = leadersRanking.filter((ranking) =>
      ranking.user.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setTotalLeadersPages(Math.ceil(filteredLeaders.length / ITEMS_PER_PAGE));
    setPaginatedLeaders(filteredLeaders.slice(0, ITEMS_PER_PAGE));
    setLeadersPage(1);
    setLeadersSearchText(searchText);
  };

  // Actualización en caso de cambios en búsqueda o página
  useEffect(() => {
    if (leadersSearchText === "") {
      if (leadersRanking) {
        setTotalLeadersPages(Math.ceil(leadersRanking.length / ITEMS_PER_PAGE));
        setPaginatedLeaders(
          leadersRanking.slice(
            (leadersPage - 1) * ITEMS_PER_PAGE,
            leadersPage * ITEMS_PER_PAGE
          )
        );
      }
    } else {
      const filteredLeaders = leadersRanking.filter((ranking) =>
        ranking.user.name
          .toLowerCase()
          .includes(leadersSearchText.toLowerCase())
      );
      setTotalLeadersPages(Math.ceil(filteredLeaders.length / ITEMS_PER_PAGE));
      setPaginatedLeaders(
        filteredLeaders.slice(
          (leadersPage - 1) * ITEMS_PER_PAGE,
          leadersPage * ITEMS_PER_PAGE
        )
      );
    }
  }, [leadersPage, leadersSearchText, leadersRanking]);
  const getRankings = () => {
    var fechaInicial = new Date(new Date().getFullYear(), 0, 1);
    var fechaFinal = new Date(new Date().getFullYear() + 1, 0, 1);

    var año_inicial = fechaInicial.getFullYear();
    var año_final = fechaFinal.getFullYear();
    var mes = ("0" + (fechaInicial.getMonth() + 1)).slice(-2);
    var dia = ("0" + fechaInicial.getDate()).slice(-2);
    var fechaFormateadaInicial = año_inicial + "-" + mes + "-" + dia; // + 'T00:00:00';
    var fechaFormateadaFinal = año_final + "-" + mes + "-" + dia; // + 'T00:00:00';

    RankingService.GetMainRanking()
      .then((response) => {
        setMainRankings(response.data.content);
      })
      .catch(() => {
        setMainRankings(null);
        toastr.error(
          t(
            "admin.ranking-message-error-get-ranking",
            "An error occurred obtaining the ranking"
          )
        );
      });

    RankingService.GetSecondaryRanking("lideres", dateFrom, dateTo)
      .then((response) => {
        setLeadersRanking(response.data.content);
      })
      .catch(() => {
        setLeadersRanking(null);
        toastr.error(
          t(
            "admin.ranking-message-error-get-ranking",
            "An error occurred obtaining the ranking"
          )
        );
      });

    RankingService.GetSecondaryRanking("agentes", dateFrom, dateTo)
      .then((response) => {
        setAgentsRanking(response.data.content);
      })
      .catch(() => {
        setAgentsRanking(null);
        toastr.error(
          t(
            "admin.ranking-message-error-get-ranking",
            "An error occurred obtaining the ranking"
          )
        );
      });

    RankingService.GetSecondaryRanking("otrosroles", dateFrom, dateTo)
      .then((response) => {
        setOthersRanking(response.data.content);
      })
      .catch(() => {
        setOthersRanking(null);
        toastr.error(
          t(
            "admin.ranking-message-error-get-ranking",
            "An error occurred obtaining the ranking"
          )
        );
      });

    RankingService.getMetricRanking()
      .then((response) => {
        setMetricRankings(response.data);
      })
      .catch(() => {
        toastr.error(
          t(
            "admin.ranking-message-error-get-ranking",
            "An error occurred obtaining the ranking"
          )
        );
      });

    RankingServices.GetHistoricalRanking(
      fechaFormateadaInicial,
      fechaFormateadaFinal
    )
      .then((response) => {
        setHistoricalRanking(response.data.content);
      })
      .catch((error) => {
        console.log(error);
      });

    /*RankingServices.GetBadgeRanking()
      .then((response) => {
        setBadgeRanking(response.data.content);
      })
      .catch((error) => {
        setBadgeRanking([]);
      });*/
  };

  const getSecondaryRankings = (fromDate = "", toDate = "") => {
    setLeadersRanking(null);
    setAgentsRanking(null);
    setOthersRanking(null);

    RankingService.GetSecondaryRanking("lideres", fromDate, toDate)
      .then((response) => {
        setLeadersRanking(response.data.content);
      })
      .catch(() => {
        setLeadersRanking(null);
        toastr.error(
          t(
            "admin.ranking-message-error-get-ranking",
            "An error occurred obtaining the ranking"
          )
        );
      });

    RankingService.GetSecondaryRanking("agentes", fromDate, toDate)
      .then((response) => {
        setAgentsRanking(response.data.content);
      })
      .catch(() => {
        setAgentsRanking(null);
        toastr.error(
          t(
            "admin.ranking-message-error-get-ranking",
            "An error occurred obtaining the ranking"
          )
        );
      });

    RankingService.GetSecondaryRanking("otrosroles", fromDate, toDate)
      .then((response) => {
        setOthersRanking(response.data.content);
      })
      .catch(() => {
        setOthersRanking(null);
        toastr.error(
          t(
            "admin.ranking-message-error-get-ranking",
            "An error occurred obtaining the ranking"
          )
        );
      });
  };

  const handleEdit = (value) => {
    setEditable(value);
  };

  const handleDisabled = (value) => {
    setDisabled(value);
  };

  const handleUpdate = () => {
    setMainRankings([]);
    getRankings();
    setEditable(null);
  };

  const handleError = () => {
    setError(!error);
    toastr.error(
      t("admin.ranking-message-error-update", "An error occurred updating")
    );
    timer = setTimeout(() => {
      setError(() => {
        return false;
      });
    }, 5000);
  };

  const downloadPointsRanking = () => {
    setDownloadingPointsRanking(true);
    RankingService.downloadPointsRanking(dateFrom, dateTo)
      .then((response) => {
        var blob = response.data;
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.download = "PointsRanking.csv";
        link.click();
        setDownloadingPointsRanking(false);
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "menu.points-coins-message-error-download-transactions",
            "An error occurred while trying to download transactions"
          )
        );
        setDownloadingPointsRanking(false);
      });
  };

  const downloadInsigniasRanking = () => {
    setDownloadingInsigniasRanking(true);
    RankingService.downloadInsigniasRanking(dateFrom, dateTo)
      .then((response) => {
        var blob = response.data;
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.download = "InsigniasRanking.csv";
        link.click();
        setDownloadingInsigniasRanking(false);
      })
      .catch(() => {
        toastr.error(
          this.props.t(
            "menu.points-coins-message-error-download-transactions",
            "An error occurred while trying to download transactions"
          )
        );
        setDownloadingInsigniasRanking(false);
      });
  };

  function handleChangeDate({ target: { name, value } }) {
    if (name == "dateTo") {
      setDateTo(value);
      getSecondaryRankings(dateFrom, value);
    }
    if (name == "dateFrom") {
      setDateFrom(value);
      getSecondaryRankings(value, dateTo);
    }
  }

  function calculate_next_day() {
    const today = new Date();
    let next_date = new Date();
    next_date.setDate(today.getDate() + 1);
    next_date = format(next_date, "yyyy-MM-dd");

    return next_date;
  }

  useEffect(() => {
    setSesion(JSON.parse(localStorage.getItem("sesion")));
    getRankings();
    return () => {
      window.clearInterval(timer);
    };
  }, []);

  const classes = useStyles();

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="lg" className={classes.container}>
        <Grid item xs={12} container>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            style={{ display: "flex", minWidth: "300px" }}
          >
            <TextField
              onChange={handleChangeDate}
              value={dateFrom}
              type="date"
              name="dateFrom"
              id="dateFrom"
              label={t(
                "menu.trivia-panel-dialog-add-test-input-dateFrom",
                "Start date"
              )}
              variant="outlined"
              className="ml-1 mr-1"
              fullWidth
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            style={{ display: "flex", minWidth: "300px" }}
          >
            <TextField
              onChange={handleChangeDate}
              value={dateTo}
              type="date"
              name="dateTo"
              id="dateTo"
              label={t(
                "menu.trivia-panel-dialog-add-test-input-dateTo",
                "Ending date"
              )}
              variant="outlined"
              className="ml-1 mr-1"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} style={{ minWidth: "300px" }}>
            {downloadingPointsRanking ? (
              <span className="spinner-container">
                <SpinnerCircular />
              </span>
            ) : (
              <Button
                className="button-table ml-1 mr-1"
                variant="contained"
                color="primary"
                onClick={() => downloadPointsRanking()}
              >
                <CloudDownloadIcon className="mr-1" />
                Descargar CSV Ranking de puntos
              </Button>
            )}
          </Grid>

          {/*<Grid item xs={12} md={6} lg={3} style={{minWidth: '300px'}}>
            {downloadingInsigniasRanking ? (
              <span className="spinner-container">
                <SpinnerCircular />
              </span>
            ) : (
            <Button
              className="button-table ml-1 mr-1"
              variant="contained"
              color="primary"
              onClick={() => downloadInsigniasRanking()}
            >
              <CloudDownloadIcon className="mr-1" />
              Descargar CSV Ranking de insignias
            </Button>
            )}
            </Grid>*/}
        </Grid>

        <div>
          {historicalRankings === null ? (
            <span className="spinner-container">
              <SpinnerCircular />
            </span>
          ) : (
            <>
              {historicalRankings.length !== 0 && (
                <span className="ranking-box">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                  >
                    <h3>{t("anual-ranking.label", "Anual ranking")}</h3>

                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder={t("search.user", "Buscar por nombre...")}
                      onChange={(e) => handleSearch(e.target.value)}
                      style={{ marginLeft: "16px" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <TableContainer
                    component={Paper}
                    style={{ height: "430px", overflowY: "auto" }}
                    className="custom-height"
                  >
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                          <TableCell
                            align="left"
                            style={{
                              fontWeight: "bold",
                              color: "#333",
                              padding: "12px",
                            }}
                          >
                            {t("ranking.label", "Ranking")}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              fontWeight: "bold",
                              color: "#333",
                              padding: "12px",
                            }}
                          >
                            {t("user.label", "User")}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              fontWeight: "bold",
                              color: "#333",
                              padding: "12px",
                            }}
                          >
                            Equipo
                          </TableCell>
                          
                          <TableCell
                            align="left"
                            style={{
                              fontWeight: "bold",
                              color: "#333",
                              padding: "12px",
                            }}
                          >
                            Equipo Secundario
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              fontWeight: "bold",
                              color: "#333",
                              padding: "12px",
                            }}
                          >
                            {t("points.label", "Points")}
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {paginatedRankings.map((ranking, index) => (
                          <TableRow
                            key={index}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#fafafa" : "#ffffff",
                              transition: "background-color 0.3s",
                              "&:hover": { backgroundColor: "#f0f0f0" },
                            }}
                          >
                            <TableCell
                              align="left"
                              style={{ padding: "15px", color: "#555" }}
                            >
                              {ranking.position && (
                                <span className="span-custom-position">
                                  {ranking.position}
                                </span>
                              )}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ padding: "15px", color: "#555" }}
                            >
                              <Box display="flex" alignItems="center">
                                <Avatar
                                  size={"2em"}
                                  style={{
                                    borderWidth: "2px",
                                    marginRight: "8px",
                                  }}
                                />
                                <Box>
                                  {ranking.user.name} {ranking.user.lastName}
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ padding: "15px", color: "#555" }}
                            >
                              {ranking.user.teamName}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ padding: "15px", color: "#555" }}
                            >
                              {ranking.user.secondaryTeamName}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ padding: "15px", color: "#555" }}
                            >
                              {ranking.pointBalance}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                    <Pagination
                      count={totalPages} // Usa el estado totalPages
                      page={page}
                      onChange={handlePageChange}
                      color="primary"
                      variant="outlined"
                      shape="rounded"
                      className="pagination-container"
                    />
                  </TableContainer>
                </span>
              )}
            </>
          )}
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <div>
              {leadersRanking === null ? (
                <span className="spinner-container">
                  <SpinnerCircular />
                </span>
              ) : (
                <>
                  <span className="ranking-box">
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                      style={{ marginTop: "30px" }}
                    >
                      <h3>Líderes</h3>
                      <TextField
                        variant="outlined"
                        size="small"
                        placeholder={t("search.user", "Buscar por nombre...")}
                        value={leadersSearchText}
                        onChange={(e) => handleLeadersSearch(e.target.value)}
                        style={{ marginLeft: "16px" }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>

                    <TableContainer
                      component={Paper}
                      className="custom-height"
                      style={{ height: "430px", overflowY: "auto" }}
                    >
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                                color: "#333",
                                padding: "12px",
                              }}
                            >
                              {t("ranking.label", "Ranking")}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                                color: "#333",
                                padding: "12px",
                              }}
                            >
                              {t("user.label", "User")}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                                color: "#333",
                                padding: "12px",
                              }}
                            >
                              Email
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                                color: "#333",
                                padding: "12px",
                              }}
                            >
                              Equipo
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                                color: "#333",
                                padding: "12px",
                              }}
                            >
                              Equipo Secundario
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                                color: "#333",
                                padding: "12px",
                              }}
                            >
                              {t("points.label", "Points")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {paginatedLeaders.map((ranking, index) => (
                            <TableRow
                              key={index}
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? "#fafafa" : "#ffffff",
                                transition: "background-color 0.3s",
                                "&:hover": { backgroundColor: "#f0f0f0" },
                              }}
                            >
                              <TableCell
                                align="left"
                                style={{ padding: "15px", color: "#555" }}
                              >
                                {ranking.position}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "15px", color: "#555" }}
                              >
                                <Box display="flex" alignItems="center">
                                  <Avatar
                                    size="2em"
                                    style={{
                                      borderWidth: "2px",
                                      marginRight: "8px",
                                    }}
                                  />
                                  <Box>
                                    {ranking.user.name} {ranking.user.lastName}
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "15px", color: "#555" }}
                              >
                                {ranking.user.email}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "15px", color: "#555" }}
                              >
                                {ranking.user.teamName}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "15px", color: "#555" }}
                              >
                                {ranking.user.secondaryTeamName}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "15px", color: "#555" }}
                              >
                                {ranking.pointBalance}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>

                      {/* Componente de paginación */}
                      <Pagination
                        count={totalLeadersPages}
                        page={leadersPage}
                        onChange={handleLeadersPageChange}
                        color="primary"
                        variant="outlined"
                        shape="rounded"
                        className="pagination-container"
                      />
                    </TableContainer>
                  </span>
                </>
              )}
            </div>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <div>
              {agentsRanking === null ? (
                <span className="spinner-container">
                  <SpinnerCircular />
                </span>
              ) : (
                <>
                  <span className="ranking-box">
                    <Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}
                        style={{ marginTop: "10px" }}
                      >
                        <h3>Agentes</h3>
                        <TextField
                          variant="outlined"
                          size="small"
                          placeholder="Buscar por nombre..."
                          value={agentsSearchText}
                          onChange={(e) => handleAgentsSearch(e.target.value)}
                          style={{ marginLeft: "16px" }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>

                      <TableContainer
                        component={Paper}
                        className="custom-height"
                        style={{ height: "450px", overflowY: "auto" }}
                      >
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                              <TableCell
                                align="left"
                                style={{
                                  fontWeight: "bold",
                                  color: "#333",
                                  padding: "12px",
                                }}
                              >
                                {t("ranking.label", "Ranking")}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  fontWeight: "bold",
                                  color: "#333",
                                  padding: "12px",
                                }}
                              >
                                {t("user.label", "User")}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  fontWeight: "bold",
                                  color: "#333",
                                  padding: "12px",
                                }}
                              >
                                Email
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  fontWeight: "bold",
                                  color: "#333",
                                  padding: "12px",
                                }}
                              >
                                Equipo
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  fontWeight: "bold",
                                  color: "#333",
                                  padding: "12px",
                                }}
                              >
                                Equipo Secundario
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  fontWeight: "bold",
                                  color: "#333",
                                  padding: "12px",
                                }}
                              >
                                {t("points.label", "Points")}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {paginatedAgentsRanking.map((ranking, index) => (
                              <TableRow
                                key={index}
                                style={{
                                  backgroundColor:
                                    index % 2 === 0 ? "#fafafa" : "#ffffff", // Alternar color de fondo
                                  transition: "background-color 0.3s",
                                  cursor: "pointer", // Cambia el cursor a puntero
                                }}
                                onMouseEnter={(e) =>
                                  (e.currentTarget.style.backgroundColor =
                                    "#f0f0f0")
                                } // Hover
                                onMouseLeave={(e) =>
                                  (e.currentTarget.style.backgroundColor =
                                    index % 2 === 0 ? "#fafafa" : "#ffffff")
                                }
                              >
                                <TableCell align="left">
                                  {ranking.position && (
                                    <span className="span-custom-position">
                                      {ranking.position}
                                    </span>
                                  )}
                                </TableCell>
                                <TableCell align="left">
                                  <Box display="flex" alignItems="center">
                                    <Avatar
                                      size={"2em"}
                                      style={{
                                        borderWidth: "2px",
                                        marginRight: "8px",
                                      }}
                                    />
                                    <Box>
                                      {ranking.user.name}{" "}
                                      {ranking.user.lastName}
                                    </Box>
                                  </Box>
                                </TableCell>
                                <TableCell align="left">
                                  {ranking.user.email}
                                </TableCell>
                                <TableCell align="left">
                                  {ranking.user.teamName}
                                </TableCell>
                                <TableCell align="left">
                                  {ranking.user.secondaryTeamName}
                                </TableCell>
                                <TableCell align="left">
                                  {ranking.pointBalance}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                        <Box mt={2} display="flex" justifyContent="center">
                          <Pagination
                            count={totalAgentsPages}
                            page={agentsPage}
                            onChange={handleAgentsPageChange}
                            color="primary"
                            variant="outlined"
                            shape="rounded"
                            className="pagination-container"
                          />
                        </Box>
                      </TableContainer>

                      {/* Paginación */}
                    </Box>
                  </span>
                </>
              )}
            </div>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <div>
              {othersRanking === null ? (
                <span className="spinner-container">
                  <SpinnerCircular />
                </span>
              ) : (
                <>
                  <span className="ranking-box">
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                      style={{ marginTop: "10px" }}
                    >
                      <h3>Otros</h3>
                      <TextField
                        variant="outlined"
                        size="small"
                        placeholder="Buscar por nombre..."
                        value={othersSearchText}
                        onChange={handleOthersSearch}
                        style={{ marginLeft: "16px" }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <TableContainer
                      component={Paper}
                      className="custom-height"
                      style={{ height: "450px", overflowY: "auto" }}
                    >
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                                color: "#333",
                                padding: "12px",
                              }}
                            >
                              {t("ranking.label", "Ranking")}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                                color: "#333",
                                padding: "12px",
                              }}
                            >
                              {t("user.label", "User")}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                                color: "#333",
                                padding: "12px",
                              }}
                            >
                              Email
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                                color: "#333",
                                padding: "12px",
                              }}
                            >
                              Equipo
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                                color: "#333",
                                padding: "12px",
                              }}
                            >
                              Equipo Secundario
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                                color: "#333",
                                padding: "12px",
                              }}
                            >
                              {t("points.label", "Points")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {paginatedOthersRanking.map((ranking, index) => (
                            <TableRow
                              key={index}
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? "#fafafa" : "#ffffff", // Alternar color de fondo
                                transition: "background-color 0.3s",
                                cursor: "pointer", // Cambia el cursor a puntero
                              }}
                              onMouseEnter={(e) =>
                                (e.currentTarget.style.backgroundColor =
                                  "#f0f0f0")
                              } // Hover
                              onMouseLeave={(e) =>
                                (e.currentTarget.style.backgroundColor =
                                  index % 2 === 0 ? "#fafafa" : "#ffffff")
                              }
                            >
                              <TableCell align="left">
                                {ranking.position && (
                                  <span className="span-custom-position">
                                    {ranking.position}
                                  </span>
                                )}
                              </TableCell>
                              <TableCell align="left">
                                <Box display="flex" alignItems="center">
                                  <Avatar
                                    size={"2em"}
                                    style={{
                                      borderWidth: "2px",
                                      marginRight: "8px",
                                    }}
                                  />
                                  <Box>
                                    {ranking.user.name} {ranking.user.lastName}
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell align="left">
                                {ranking.user.email}
                              </TableCell>
                              <TableCell align="left">
                                {ranking.user.teamName}
                              </TableCell>
                              <TableCell align="left">
                                {ranking.user.secondaryTeamName}
                              </TableCell>
                              <TableCell align="left">
                                {ranking.pointBalance}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <Box mt={2} display="flex" justifyContent="center">
                        <Pagination
                          count={totalOthersPages}
                          page={othersPage}
                          onChange={handleOthersPageChange}
                          color="primary"
                          variant="outlined"
                          shape="rounded"
                          className="pagination-container"
                        />
                      </Box>
                    </TableContainer>
                  </span>
                </>
              )}
            </div>
          </Grid>
        </Grid>

        {mainRanking === null ? (
          <span className="spinner-container">
            <SpinnerCircular />
          </span>
        ) : (
          <>
            {mainRanking.length !== 0 && (
              <React.Fragment>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  style={{ marginTop: "15px" }}
                >
                  <h3>{t("ranking.label", "Ranking")}</h3>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Buscar por nombre..."
                    value={mainSearchText}
                    onChange={handleMainSearch}
                    style={{ marginLeft: "16px" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <TableContainer
                  component={Paper}
                  className="ranking-list-content ranking-list-content-custom-height"
                  style={{ height: "480px", overflowY: "auto" }}
                >
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                        <TableCell
                          align="left"
                          style={{
                            fontWeight: "bold",
                            color: "#333",
                            padding: "12px",
                          }}
                        >
                          {t("menu.rigth-list-item-users", "Users")}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            fontWeight: "bold",
                            color: "#333",
                            padding: "12px",
                          }}
                        >
                          {t("coins.label", "Coins")}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            fontWeight: "bold",
                            color: "#333",
                            padding: "12px",
                          }}
                        >
                          {t("points.label", "Points")}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            fontWeight: "bold",
                            color: "#333",
                            padding: "12px",
                          }}
                        >
                          {t("admin.user-panel-table-actions", "Actions")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedMainRanking.map((data, index) => {
                        return (
                          <UserRanking
                            name={`${data.user.name} ${data.user.lastName}`}
                            points={data.pointBalance}
                            coins={data.coinBalance}
                            id={sesion.user.id}
                            userId={data.user.id}
                            key={data.user.id}
                            onEdit={handleEdit}
                            editable={data.user.id === editable}
                            onUpdate={handleDisabled}
                            onUpdated={handleUpdate}
                            disabled={disabled}
                            onError={handleError}
                            index={index}
                          />
                        );
                      })}
                    </TableBody>
                  </Table>
                  <Pagination
                    count={totalMainPages}
                    page={mainPage}
                    onChange={handleMainPageChange}
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    className="pagination-container"
                  />
                </TableContainer>
              </React.Fragment>
            )}
          </>
        )}

        {/*<div>
          {badgeRankings === null ? (
            <span className="spinner-container">
              <SpinnerCircular />
            </span>
          ) : (
            <>
              {badgeRankings.length !== 0 && (
                <span className="ranking-box">
                  <h3>{t("menu.rigth-list-item-badges", "Badges")}</h3>

                  <TableContainer component={Paper} className="custom-height">
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">
                            {t("ranking.label", "Ranking")}
                          </TableCell>
                          <TableCell align="left">
                            {t("user.label", "User")}
                          </TableCell>
                          <TableCell align="left">
                            {t("menu.rigth-list-item-badges", "Badges")}
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {badgeRankings.map((ranking, index) => (
                          <TableRow key={index}>
                            <TableCell align="left">
                              {ranking.position && (
                                <span className="span-custom-position">
                                  {ranking.position}
                                </span>
                              )}
                            </TableCell>

                            <TableCell align="left" className="d-flex-custom">
                              <Avatar
                                size={"2em"}
                                style={{ borderWidth: "2px" }}
                              />{" "}
                              {ranking.user.name} {ranking.user.lastName}
                            </TableCell>

                            <TableCell align="left">
                              {ranking.insigniaCount
                                ? ranking.insigniaCount
                                : "N/A"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </span>
              )}
            </>
          )}
        </div>*/}

        <div>
          {metricsRankings === null ? (
            <span className="spinner-container">
              <SpinnerCircular />
            </span>
          ) : (
            <>
              {metricsRankings.length !== 0 &&
                metricsRankings.map((data, index) => (
                  <span className="ranking-box" key={index}>
                  <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  style={{ marginTop: "15px" }}
                >
                  <h3>{data.metricConf.name}</h3>

      <TextField
        variant="outlined"
                    size="small"
                    placeholder="Buscar por nombre..."
        value={searchText2}
        onChange={handleSearchChange}
        style={{ marginLeft: "16px" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
      />
</Box>
<TableContainer
  component={Paper}
  className="custom-height"
  style={{ height: "450px", overflowY: "auto" }}
>
  <Table aria-label="simple table">
    <TableHead>
      <TableRow style={{ backgroundColor: "#f5f5f5" }}>
        <TableCell
          align="left"
          style={{
            fontWeight: "bold",
            color: "#333",
            padding: "12px",
          }}
        >
          {t("user.label", "User")}
        </TableCell>
        <TableCell
          align="left"
          style={{
            fontWeight: "bold",
            color: "#333",
            padding: "12px",
          }}
        >
          {t("points.label", "Points")}
        </TableCell>
      </TableRow>
    </TableHead>

    <TableBody>
      {paginatedUsers.map((user, index) => (
        <TableRow
          key={index}
          style={{
            backgroundColor: index % 2 === 0 ? "#fafafa" : "#ffffff", // Alternar color de fondo
            transition: "background-color 0.3s",
            cursor: "pointer", // Cambia el cursor a puntero
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor = "#f0f0f0") // Hover
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.backgroundColor =
              index % 2 === 0 ? "#fafafa" : "#ffffff")
          }
        >
          <TableCell align="left" style={{ padding: "16px", color: "#333" }}>
            <Box display="flex" alignItems="center">
              <Avatar
                size={"2em"}
                style={{
                  borderWidth: "2px",
                  marginRight: "8px",
                }}
              />
              <Box>
                {user.user.name} {user.user.lastName}
              </Box>
            </Box>
          </TableCell>

          <TableCell align="left" style={{ padding: "15px", color: "#333" }}>
            {user.points}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
  <Pagination
          count={totalPages2}
          page={currentPage}
          onChange={handlePageChange2}
          color="primary"
                    variant="outlined"
                    shape="rounded"
                    className="pagination-container"
        />
</TableContainer>

     
      <Box mt={2} display="flex" justifyContent="center">
       
      </Box>
                  </span>
                ))}
            </>
          )}
        </div>
      </Container>
    </main>
  );
}
